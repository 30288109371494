var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { staticClass: "nav-item" },
    [
      _c(
        "router-link",
        { staticClass: "nav-link", attrs: { to: _vm.to, exact: _vm.exact } },
        [
          _vm.old_icon
            ? _c("i", { class: _vm.icon + " float-left" })
            : _vm._e(),
          _vm._v(" "),
          _c("span", [_vm._t("default")], 2)
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }